import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useMe } from '../../routes/_app/use-me.ts';
import { getOnboardingState } from '../../utils/get-onboarding-state.ts';
import { Portal } from '@synoptic/ui-kit/portal.tsx';

const OnboardingAlert = React.lazy(() =>
  import('./onboarding-alert.tsx').then((module) => ({
    default: module.OnboardingAlert,
  })),
);

export const Onboarding: React.FC = () => {
  const me = useMe();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(
    location.state?.afterAuth &&
      !me?.protectedFields?.isOnboardingFinished &&
      getOnboardingState(me.createdAt),
  );

  return showPopup ? (
    <Portal asChild>
      <OnboardingAlert
        fullName={me.fullName}
        onClose={() => setShowPopup(false)}
      />
    </Portal>
  ) : null;
};
